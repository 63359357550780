import { format } from 'date-fns/fp';

const priceListService = process.env.REACT_APP_BACKEND_URL ? process.env.REACT_APP_BACKEND_URL : 'http://127.0.0.1:4031';

const priceListServiceGraphql = `${priceListService}/pricelist/graphql`;

interface IOfferDetailsInput {
  hotelRef: string;
  offerVersionId: number;
}

interface IQuickPriceInput {
  hotelRef: string;
  language: string;
  startDate: Date;
  endDate: Date;
  days: any[];
  serviceTypeSku: string;
  prevdayGuests: number;
  comment: string | null;
}

interface IDownloadInput extends IQuickPriceInput {
  client: {
    company: string;
    firstname: string;
    lastname: string;
    email: string;
    country: string;
    phone: string;
    address: string;
    city: string;
    zip: string;
    billingCompany: string;
    billingFirstname: string;
    billingLastname: string;
    billingAddress: string;
    billingCity: string;
    billingZip: string;
    billingCountry: string;
  };
}

interface IReservation {
  input: IQuickPriceInput;
  client: {
    company: string;
    firstname: string;
    lastname: string;
    email: string;
    country: string;
    phone: string;
    address: string;
    city: string;
    zip: string;
    billingCompany: string;
    billingFirstname: string;
    billingLastname: string;
    billingAddress: string;
    billingCity: string;
    billingZip: string;
    billingCountry: string;
  };
}

interface IServiceTypesInput {
  hotelRef: string;
  language: string;
}

interface IWidgetConfig {
  widgetSemPlan: string | null;
  widgetHotelBusinessName: string;
  widgetColor: string;
  widgetColorEnabled: boolean;
  widgetColorInherited?: string | undefined;
  widgetPrivacyUrl: string;
  widgetTermsUrl: string;
  widgetNoRooms: boolean;
  widgetExpandedMode: boolean;
  widgetSlimMode: boolean;
}

export async function approveOfferVersion(offerVersionId: number) {
  let res = await fetch(priceListServiceGraphql, {
    method: 'POST',

    headers: {
      'Content-Type': 'application/json',
    },

    body: JSON.stringify({
      query: `mutation ApproveOfferVersionClient($offerVersionId: Int!) {
        approveOfferVersionClient(offerVersionId: $offerVersionId)
      }`,
      variables: {
        offerVersionId: offerVersionId,
      },
    }),
  });
  const json = await res.json();
  if (json.errors && json.errors.length > 0) {
    throw new Error(json.errors[0].message, { cause: json.errors });
  } else if (json.data && json.data.approveOfferVersionClient) {
    return json.data.approveOfferVersionClient;
  } else {
    return null;
  }
}

export async function rejectOfferVersion(offerVersionId: number) {
  let res = await fetch(priceListServiceGraphql, {
    method: 'POST',

    headers: {
      'Content-Type': 'application/json',
    },

    body: JSON.stringify({
      query: `mutation RejectOfferVersionClient($offerVersionId: Int!) {
        rejectOfferVersionClient(offerVersionId: $offerVersionId)
      }`,
      variables: {
        offerVersionId: offerVersionId,
      },
    }),
  });
  const json = await res.json();
  if (json.errors && json.errors.length > 0) {
    throw new Error(json.errors[0].message, { cause: json.errors });
  } else if (json.data && json.data.rejectOfferVersionClient) {
    return json.data.rejectOfferVersionClient;
  } else {
    return null;
  }
}

export async function getHTMLOfferPreview(reservation: IReservation) {
  let res = await fetch(priceListServiceGraphql, {
    method: 'POST',

    headers: {
      'Content-Type': 'application/json',
    },

    body: JSON.stringify({
      query: `query OfferQuickInputHtmlPreview($hotelRef: String!, $input: QuickPriceInput!, $client: QuickOfferClient!) {
        offerQuickInputHtmlPreview(hotelRef: $hotelRef, input: $input, client: $client) {
          html
        }
      }`,
      variables: {
        hotelRef: reservation.input.hotelRef,
        input: {
          language: reservation.input.language,
          startDate: format('yyyy-MM-dd', reservation.input.startDate),
          endDate: format('yyyy-MM-dd', reservation.input.endDate),
          days: reservation.input.days,
          prevdayGuests: reservation.input.prevdayGuests,
          serviceTypeSku: reservation.input.serviceTypeSku,
          comment: reservation.input.comment,
        },
        client: reservation.client,
      },
    }),
  });
  const json = await res.json();
  if (json.errors && json.errors.length > 0) {
    throw new Error(json.errors[0].message, { cause: json.errors });
  } else if (json.data && json.data.offerQuickInputHtmlPreview) {
    return json.data.offerQuickInputHtmlPreview;
  } else {
    return null;
  }
}

export async function getOfferDetails(offerDetailsInput: IOfferDetailsInput) {
  let res = await fetch(priceListServiceGraphql, {
    method: 'POST',

    headers: {
      'Content-Type': 'application/json',
    },

    body: JSON.stringify({
      query: `query OfferDetails($hotelRef: String!, $input: OfferDetailsInput!) {
        offerDetails(hotelRef: $hotelRef, input: $input) {
          input {
            days {
              addons {
                sku
                count
              }
            }
          }
          html
          htmlWithChanges
          actionRequired
        }
      }`,
      variables: {
        hotelRef: offerDetailsInput.hotelRef,
        input: {
          offerVersionId: offerDetailsInput.offerVersionId,
        },
      },
    }),
  });
  const json = await res.json();
  if (json.errors && json.errors.length > 0) {
    throw new Error(json.errors[0].message, { cause: json.errors });
  } else if (json.data && json.data.offerDetails) {
    return json.data.offerDetails;
  } else {
    return null;
  }
}

export async function getWidgetConfig(hotelRef?: string): Promise<IWidgetConfig | null> {
  if (!hotelRef) throw new Error('hotelRef is required');
  let res = await fetch(priceListServiceGraphql, {
    method: 'POST',

    headers: {
      'Content-Type': 'application/json',
    },

    body: JSON.stringify({
      query: `query WidgetConfigQuery($hotelRef: String!) {
        widgetConfig(hotelRef: $hotelRef) {
          widgetSemPlan
          widgetHotelBusinessName
          widgetColor
          widgetColorEnabled
          widgetColorInherited
          widgetPrivacyUrl
          widgetTermsUrl
          widgetNoRooms
          widgetExpandedMode
          widgetSlimMode
        }
      }`,
      variables: {
        hotelRef: hotelRef,
      },
    }),
  });
  const json = await res.json();
  if (json.errors && json.errors.length > 0) {
    throw new Error(json.errors[0].message, { cause: json.errors });
  } else if (json.data && json.data.widgetConfig) {
    return json.data.widgetConfig;
  } else {
    return null;
  }
}

export async function getQuickPriceDehydrate(hotelRef: string) {
  let res = await fetch(priceListServiceGraphql, {
    method: 'POST',

    headers: {
      'Content-Type': 'application/json',
    },

    body: JSON.stringify({
      query: `query QuickPriceDehydrate($hotelRef: String!) {
        quickPriceDehydrate(hotelRef: $hotelRef)
      }`,
      variables: {
        hotelRef: hotelRef,
      },
    }),
  });
  const json = await res.json();
  if (json.errors && json.errors.length > 0) {
    throw new Error(json.errors[0].message, { cause: json.errors });
  } else if (json.data && json.data.quickPriceDehydrate) {
    return json.data.quickPriceDehydrate;
  } else {
    return null;
  }
}

export async function getServiceTypes(servicesTypesInput: IServiceTypesInput) {
  let res = await fetch(priceListServiceGraphql, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      query: `query ListServiceTypes($hotelRef: String!, $language: String) {
        quickPriceDehydrate(hotelRef: $hotelRef)
        availabilityDehydrate(hotelRef: $hotelRef)
        listServiceTypes(hotelRef: $hotelRef, language: $language) {
          addonFacilities {
            sku
            name
            header
            details
            lookupCode
            recurring
          }
          addonProducts {
            sku
            name
            header
            details
            isAddon
            isDeduction
            lookupCode
            recurring
          }
          serviceProducts {
            details
            group
            header
            isAddon
            isDeduction
            lookupCode
            name
            recurring
            sku
          }
          details
          header
          headerShort
          maxDayVisitors
          maxOvernight
          minDayVisitors
          minOvernight
          name
          sku
        }
      }`,
      variables: {
        hotelRef: servicesTypesInput.hotelRef,
        language: servicesTypesInput.language,
      },
    }),
  });
  const json = await res.json();
  if (json.errors && json.errors.length > 0) {
    throw new Error(json.errors[0].message, { cause: json.errors });
  } else if (json.data && json.data.listServiceTypes) {
    return {
      serviceTypes: json.data.listServiceTypes,
      availabilityDehydrate: json.data.availabilityDehydrate,
      quickPriceDehydrate: json.data.quickPriceDehydrate,
    };
  } else {
    return null;
  }
}

export const placeReservation = async (reservation: IReservation) => {
  let res = await fetch(priceListServiceGraphql, {
    method: 'POST',

    headers: {
      'Content-Type': 'application/json',
    },

    body: JSON.stringify({
      query: `mutation PlaceReservation($hotelRef: String!, $input: QuickPriceInput!, $client: QuickOfferClient!) {
        placeReservation(hotelRef: $hotelRef, input: $input, client: $client) {
          cancelUrl
          emailVerificationRequired
          refCode
          viewUrl
        }
      }`,
      variables: {
        hotelRef: reservation.input.hotelRef,
        input: {
          language: reservation.input.language,
          startDate: reservation.input.startDate,
          endDate: reservation.input.endDate,
          days: reservation.input.days,
          prevdayGuests: reservation.input.prevdayGuests,
          serviceTypeSku: reservation.input.serviceTypeSku,
          comment: reservation.input.comment,
        },
        client: reservation.client,
      },
    }),
  });
  const json = await res.json();
  if (json.errors && json.errors.length > 0) {
    throw new Error(json.errors[0].message, { cause: json.errors });
  } else if (json.data && json.data.placeReservation) {
    return json.data.placeReservation;
  } else {
    return null;
  }
};

export const verifyReservationEmail = async (hotelRef: string, code: string): Promise<boolean> => {
  let res = await fetch(priceListServiceGraphql, {
    method: 'POST',

    headers: {
      'Content-Type': 'application/json',
    },

    body: JSON.stringify({
      query: `mutation VerifyReservationEmail($hotelRef: String!, $code: String!) {
        verifyReservationEmail(hotelRef: $hotelRef, code: $code)
      }`,
      variables: {
        hotelRef: hotelRef,
        code: code,
      },
    }),
  });
  const json = await res.json();
  if (json.errors && json.errors.length > 0) {
    throw new Error(json.errors[0].message, { cause: json.errors });
  } else if (json.data && json.data.verifyReservationEmail) {
    return !!json.data.verifyReservationEmail;
  } else {
    return false;
  }
};

export const getDownloadUrl = (quickPriceInput: IDownloadInput) => {
  const query = new URLSearchParams({
    data: JSON.stringify({
      ...quickPriceInput,
      startDate: format('yyyy-MM-dd', quickPriceInput.startDate),
      endDate: format('yyyy-MM-dd', quickPriceInput.endDate),
    }),
  }).toString();
  return `${priceListService}/pricelist/quickprint/${quickPriceInput.hotelRef}?${query}`;
};
