import React, { useRef } from 'react';
import styled from 'styled-components';

const BaseInput = styled.input`
  border: none;
  background-color: transparent;
  &:focus {
    outline: none;
  }
  padding: 10px;
  font-size: 16px;
  width: 100%;
  box-sizing: border-box;
  margin-top: -15px;
`;

const BaseInputTextArea = styled.textarea`
  border: none;
  background-color: transparent;
  &:focus {
    outline: none;
  }
  padding: 10px;
  font-size: 16px;
  width: 100%;
  box-sizing: border-box;
`;

const InputContainer = styled.div<{ width: string; grey: boolean }>`
  background-color: ${props => (props.grey ? 'rgb(238, 244, 252)' : '#fff')};
  padding: 10px;
  border-radius: 10px;
  width: ${props => props.width};
  display: inline-block;
  cursor: text;
  margin: 5px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  &:has(> input:focus) {
    border: 2px solid #000;
  }
  &:has(> textarea:focus) {
    border: 2px solid #000;
  }
`;

interface TextFieldProps {
  errors: any;
  name: string;
  label: string;
  cols?: number;
  fullWidth?: boolean;
  multiline?: boolean;
  rows?: number;
  grey?: boolean;
  onChange: (e: any) => void;
  value: string;
  htmlFor: string;
}

const TextField = ({ errors, name, cols, fullWidth, multiline, grey, htmlFor, ...inputProps }: TextFieldProps) => {
  const ref = useRef<any>(null);

  const width = (cols || 1) * 280;
  return (
    <div style={{ display: 'inline-block', width: fullWidth ? '100%' : `auto` }}>
      <InputContainer
        grey={grey || false}
        width={fullWidth ? '100%' : `${width - 30}px`}
        onClick={() => {
          if (ref && ref.current) {
            ref.current.focus();
          }
        }}
      >
        <label style={{ fontSize: 12, paddingBottom: 5, display: 'inline-block' }} htmlFor={htmlFor}>
          {inputProps.label}
        </label>
        {multiline ? (
          <BaseInputTextArea {...inputProps} id={htmlFor} name={htmlFor} ref={ref} />
        ) : (
          <BaseInput id={htmlFor} name={htmlFor} type="text" {...inputProps} ref={ref} />
        )}
      </InputContainer>
      <div style={{ color: 'red', fontSize: 12, paddingLeft: 10 }}>
        {errors && errors[name] && errors[name]}
        {errors && !errors[name] && '\u00A0'}
      </div>
    </div>
  );
};

export default TextField;
